import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As users, we’ve almost come to accept that even though our phones and computers are massively more powerful, most software we use feels pretty sluggish and is often buggy. As developers however, the common wisdom is that programmer productivity is more important than performance.`}</p>
    <p>{`We all know we prefer the snappy, responsive products that start instantly and don’t drain our batteries. But since we perceive this kind of performance costly to achieve, what other reasons are there to do so?`}</p>
    <p>{`First, especially if you’re software for people that have to use it daily to get their job done, the performance of your software has a cascading effect on people’s lives. If even 100,000 people lose 5 minutes every day because of your slow software, that’s 500,000 wasted minutes every day! That’s a big abstract number, but think about how compilation times, Webpack builds, etc. affect your productivity. It takes you out of your flow and takes away a large part of the joy of programming.`}</p>
    <p>{`Second, which inspires me the most, is that if you’re software is faster, you can also process more data quicker and provide new experiences. I want to see those futuristic workflows which we’ve been promised in movies. For example, you could visualize large, complex systems in interesting ways. Or provide designers with tools to see how their changes reflect across an entire product instantly, maybe even in meetings with other team members who can have their own, complementary perspectives and tools.`}</p>
    <p>{`Lastly, if you’re working on any sort of product that works over the internet, more efficient software translates directly to less operational costs, in the form of less servers, less “server-less function” running time, etc. And, less latency for your users.`}</p>
    <p>{`But if we want performant software, we need to also simplify how we write code so we can reason from first principles about performance and limit the potential for bugs. A lot of the time, programmers push complexity down the stack by using all kinds of libraries and frameworks without understanding the trade-offs of using them. But that complexity is still there, it’s just hidden from you.`}</p>
    <p>{`Currently, I’m exploring low-level programming using Zig to build a software architecture visualization tool. I’m writing it in the simplest, most naive way using only GLFW and OpenGL, only adding complexity where needed. I wrote a YAML and Typescript parser from scratch, with only the features I need so I can reduce problems which could be quite complex down to something manageable to quickly experiment with. This project is my personal playground in trade-offs, programming techniques and performance investigation. If that sounds interesting, stay in touch!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      